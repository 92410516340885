import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configuration-for-city-of-helsinki-django-projects"
    }}>{`Configuration for City of Helsinki Django projects`}</h1>
    <p>{`Configuration for all new projects should be done using environment variables if at all possible.`}</p>
    <p>{`Projects should use `}<a parentName="p" {...{
        "href": "https://pypi.org/project/django-environ/"
      }}>{`django-environ`}</a>{` for reading environment
variables. This makes it easy to inspect available settings from settings.py`}</p>
    <h2 {...{
      "id": "usage-example"
    }}>{`Usage example`}</h2>
    <p>{`Specify the available variables using the `}<inlineCode parentName="p">{`environ.Env`}</inlineCode>{` constructor. Include types and default values here.
This makes for a nice single-point-of-truth for available settings.`}</p>
    <pre><code parentName="pre" {...{}}>{`env = environ.Env(
    DEBUG=(bool, True),
    SECRET_KEY=(str, ''),
    ALLOWED_HOSTS=(list, []),
    ADMINS=(list, []),
    DATABASE_URL=(str, 'postgis://mvj:mvj@localhost/mvj'),
    CACHE_URL=(str, 'locmemcache://'),
    EMAIL_URL=(str, 'consolemail://'),
    SENTRY_DSN=(str, ''),
    TOKEN_AUTH_ACCEPTED_AUDIENCE=(str, ''),
    TOKEN_AUTH_ACCEPTED_SCOPE_PREFIX=(str, ''),
    TOKEN_AUTH_AUTHSERVER_URL=(str, ''),
    TOKEN_AUTH_FIELD_FOR_CONSENTS=(str, ''),
    TOKEN_AUTH_REQUIRE_SCOPE_PREFIX=(bool, True),
)
`}</code></pre>
    <p>{`Access parsed values using your defined `}<inlineCode parentName="p">{`env`}</inlineCode>{`. Authentication config is a typical case:`}</p>
    <pre><code parentName="pre" {...{}}>{`OIDC_API_TOKEN_AUTH = {
    'AUDIENCE': env('TOKEN_AUTH_ACCEPTED_AUDIENCE'),
    'API_SCOPE_PREFIX': env('TOKEN_AUTH_ACCEPTED_SCOPE_PREFIX'),
    'ISSUER': env('TOKEN_AUTH_AUTHSERVER_URL'),
    'API_AUTHORIZATION_FIELD': env('TOKEN_AUTH_FIELD_FOR_CONSENTS'),
    'REQUIRE_API_SCOPE_FOR_AUTHENTICATION': env('TOKEN_AUTH_REQUIRE_SCOPE_PREFIX'),
}
`}</code></pre>
    <h2 {...{
      "id": "authentication-config-for-apis"
    }}>{`Authentication config for APIs`}</h2>
    <p>{`Software implementing an APIs need to often need the ability to authenticate
incoming requests, fe. when personal data is requested. City of Helsinki
projects use a token authentication based on OIDC/Oauth stuff.`}</p>
    <p>{`The software component handling this is `}<inlineCode parentName="p">{`django-helusers`}</inlineCode>{`. Which needs
OIDC_API_TOKEN_AUTH dict filled as seen above. For easier operations the
configuration variables should be mapped to environment variables as seen
above.`}</p>
    <h3 {...{
      "id": "meaning-of-the-environment-variables"
    }}>{`Meaning of the environment variables`}</h3>
    <p><inlineCode parentName="p">{`TOKEN_AUTH_ACCEPTED_AUDIENCE`}</inlineCode>{`: specifies the "aud"-field that should be
present in the token presented by the client. This is an URL identifying
a single deployment of your API software. However, it is not the url where
the API is located, but instead an identifier in the namespace used by
authentication server. For example: `}<inlineCode parentName="p">{`https://api.hel.fi/auth/haravadev`}</inlineCode></p>
    <p><inlineCode parentName="p">{`TOKEN_AUTH_AUTHSERVER_URL`}</inlineCode>{`: specifies the URL the the authentication server
standard OIDC-endpoint. As opposed to the "accepted audience" setting, this
is a honest to goodness URL. The libraries handling OIDC will expect to find
`}<inlineCode parentName="p">{`.well-known/openid-configuration`}</inlineCode>{` path under this url. Example:
`}<inlineCode parentName="p">{`https://api.hel.fi/sso-test/openid/`}</inlineCode></p>
    <p>{`There are also other variables. I've forgotten what they mean.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      